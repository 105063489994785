import React, { useContext } from 'react';
import styled from "styled-components";
import { UIContext } from './UIContext';
import { Link } from "gatsby";
import Banner from "./Banner";
import Multilinks from "./Multilinks";
import CtaPrimary from "./CtaPrimary";
import Img from "./Img";
import RatingWidget from "./RatingWidget";

const StyledMainLeftColumn = styled.div`
  grid-area: left;
  border-right: 1px solid rgba(0, 0, 0, 0.3);
  .card:first-child {
    padding-bottom: 0;
  }
`;

// "Master Your Data with Expert Analytics"
// "Insights from Advanced Web Analytics"
// "Drive Your Success with Detailed Analytics"
// "Unlock the Power of Data with Professional Analytics"
// "Analytics Expertise to Propel Your Business"
// "Transform Data into Strategy with Advanced Analytics"
// "Data-Driven Decisions Made Simple"
// "Expert Analytics for Measurable Success"
// "Harness the Full Potential of Your Data"
// "Precision Analytics for Optimal Performance"
const bannerHeadline = {
  en: "“Master Traffic & Ads with Advanced Analytics Services”",
  de: "“Meistere Traffic & Ads: Analytics-Dienste vom Profi”",
  da: "“Mestre Trafik & Ads med Avancerede Analytics-tjenester”",
  se: "“Bemästra Trafik & Ads med Avancerade Analytics-tjänster”",
  no: "“Mestre Trafikk & Ads med Avanserte Analytics-tjenester”",
  nl: "“Beheers Verkeer & Ads met Geavanceerde Analytics”",
  fi: "“Hallitse Liikenne & Mainokset Edistyneillä Analytiikkapalveluilla”",
  fr: "“Maîtrisez le Trafic & les Publicités avec des Services d'Analytique Avancés”"
};

const bannerCta = {
  en: "Services",
  de: "Leistungen",
  da: "Tjenester",
  se: "Tjänster",
  no: "Tjenester",
  nl: "Diensten",
  fi: "Palvelut",
  fr: "Services"
};

const serviceLinks = {
  en: "/en/services",
  de: "/de/leistungen",
  da: "/da/services",
  fi: "/fi/palvelut",
  fr: "/fr/les-services",
  nl: "/nl/diensten",
  no: "/no/tjenester",
  se: "/se/tjänster",
  x_default: "/en/services"
};

const MainLeftColumn = () => {
  const { language } = useContext(UIContext); // Destructured for direct access
  const lang = language || "en"; // Fallback to "en" if language is not defined

  // Determine the correct link, falling back to x-default if not found
  const serviceLink = serviceLinks[lang] || serviceLinks.x_default;

  return (
    <StyledMainLeftColumn className="main-left">
      <Banner>
        <h1 className="banner__headline text headline">{bannerHeadline[lang]}</h1>
        <div className="banner__image">
          <Img clickable="false" src="about/matthias-kupperschmidt-frontal-collar-transparent-square.png" alt="matthias kupperschmidt portrait" />
        </div>

        <div className="features">
          <Link className="cta-primary" to={serviceLink}>
            <CtaPrimary color="red" className="cta-primary--align">{bannerCta[lang]}</CtaPrimary>
          </Link>
          <RatingWidget className="rating" lang={language} />
        </div>
      </Banner>
      <Multilinks language={lang} genre={"analytics"} />
    </StyledMainLeftColumn>
  );
};

export default React.memo(MainLeftColumn);
